@import "/public/css/breakpoints.scss";

#waiting-bar-mobile {
  display: flex;

  @include breakpoint("tablet") {
    display: none;
  }
}

#waiting-bar-desktop {
  display: none;

  @include breakpoint("tablet") {
    display: flex;
  }
}
