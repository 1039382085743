.float-label-control {
  label.empty {
    font-size: 14px;
    line-height: 20px;
    top: 16px;
    color: #737373;
    animation: none;
    -webkit-animation: none;
  }
}

.float-label-control input:not(.empty):not(:focus) {
  border-bottom-color: #737373;
}

.float-label-control {
  input:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom-width: 2px;
    padding-bottom: 6px;
    border-bottom-color: #004b87;
    background-color: rgba(107, 206, 245, 0.12);
    color: #000000;
  }
}
