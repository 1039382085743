@import "/public/css/breakpoints.scss";

.form-heading {
  text-align: center;

  @include breakpoint("tablet") {
    text-align: center;
  }
}

#logo_heading {
  display: grid;

  @include breakpoint("tablet") {
    display: flex;
  }
}

#ourSage_logo_mobile {
  margin-top: 2px;
  display: block;

  @include breakpoint("tablet") {
    display: none;
  }
}

#ourSage_logo_tablet {
  margin-top: 2px;
  display: none;

  @include breakpoint("tablet") {
    display: block;
  }
}

#prev_button,
#prev_light_button {
  margin-top: -12px;
}

#prev_button {
  cursor: pointer;
}

#exit_button {
  margin-top: -7px;
}

/*
if (parentwidth >= 576){
		$("#form_heading").css({'text-align': 'left'})
		$("#logo_heading").css({'display': 'flex'})
		$("#exit_button").css({'vertical-align': 'center'})
		$("#prev_light_button").css({'vertical-align': 'center'})
		$("#prev_button").css({'vertical-align': 'center'})
		$("#ourSage_logo").css({'height': '25.71'})
	} else {
		$("#form_heading").css({'text-align': 'center'})
		$("#logo_heading").css({'display': 'grid'})
		$("#exit_button").css({'vertical-align': 'bottom'})
		$("#prev_light_button").css({'vertical-align': 'bottom'})
		$("#prev_button").css({'vertical-align': 'bottom'})
		$("#ourSage_logo").css({'height': '20.57'})
	}
*/
