/*
* Map for breakpoints
*/
$breakpoints: (
  "tablet": ( min-width: 576px)
) !default; 

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }
}